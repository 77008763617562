.dialogue {
  background-image: url("./assets/textbox_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 800px;
  height: 128px;
}
.portrait-name {
  background-image: url("./assets/textbox_name.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 32px;
  min-width: 100%;
  @apply w-fit;
}

.portrait {
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: 50% 10%;
  height: 100px;
  width: 95px;
  left: 5px;
  top: 5px;
}

/* .winterrose.completed {
  background-image: url("./assets/NPCs/Wise/profile.png");
}

.pc-07.completed {
  background-image: url("./assets/NPCs/Provider/profile.png");
}

.baatar.completed {
  background-image: url("./assets/NPCs/Bargain Hunter/profile.png");
}

.aljunied.completed {
  background-image: url("./assets/NPCs/Accumulator/profile.png");
}

.tamarind.completed {
  background-image: url("./assets/NPCs/Trader/profile.png");
}
*/

.winterrose {
  background-image: url("./assets/NPCs/Wise/Portrait_Talk.gif");
}

.pc-07 {
  background-image: url("./assets/NPCs/Provider/Portrait_Talk.gif");
}

.baatar {
  background-image: url("./assets/NPCs/Bargain Hunter/Portrait_Talk.gif");
}

.aljunied {
  background-image: url("./assets/NPCs/Accumulator/Portrait_Talk.gif");
}

.tamarind {
  background-image: url("./assets/NPCs/Trader/Portrait_Talk.gif");
}
