@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");

@font-face {
  font-family: Pixolletta;
  src: url("./assets/Pixolletta8px.ttf");
}

html {
  font-size: 12px;
}

body {
  margin: 0;
  font-family: "Press Start 2P", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1b1a1a;
}

.font-pixolletta {
  font-family: Pixolletta;
  letter-spacing: 0.2px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.table-header {
  @apply w-44 text-center font-pixolletta text-lg text-white tracking-wide font-bold;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
