.hover {
  animation: MoveUpDown 2s ease-in-out infinite;
  transform: translateY(0%)
}

@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(0%)
  }
  50% {
    transform: translateY(20%)
  }
}

.esc-btn {
  background-image: url('./assets/Buttons/Esc Btn/esc_btn.png');
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 64px;
  width: 98px; */
  height: 44.8px;
  width: 68.6px;
  display: flex;
  justify-content: center;
}

.esc-btn:hover {
  background-image: url('./assets/Buttons/Esc Btn/esc_btn_select.png');
}

.esc-btn:active {
  background-image: url('./assets/Buttons/Esc Btn/esc_btn_pressed.png');
}

.long-btn {
  background-image: url('./assets/Buttons/Long Btn/long_btn.png');
  background-color: transparent;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* height: 60px;
  width: 228px; */
  height: 48px;
  min-width: 182.4px;
  @apply flex justify-center items-center;
}

.long-btn:hover {
  background-image: url('./assets/Buttons/Long Btn/long_btn_select.png');
}

.long-btn:active {
  background-image: url('./assets/Buttons/Long Btn/long_btn_pressed.png');
}

.med-btn {
  background-image: url('./assets/Buttons/Medium Btn/btn.png');
  background-color: transparent;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* width: 172px;
  height: 60px; */
  width: 137.6px;
  height: 48px;
  @apply flex justify-center items-center;
}

.med-btn:hover {
  background-image: url('./assets/Buttons/Medium Btn/btn_select.png');
}

.med-btn:active {
  background-image: url('./assets/Buttons/Medium Btn/btn_pressed.png');
}

.sm-btn {
  background-image: url('./assets/Buttons/Small Btn/index_btn.png');
  background-color: transparent;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* width: 90px;
  height: 46px; */
  width: 54px;
  height: 27.6px;
  @apply flex justify-center items-center;
}

.sm-btn:hover {
  background-image: url('./assets/Buttons/Small Btn/index_btn_select.png'); 
}

.sm-btn:active {
  background-image: url('./assets/Buttons/Small Btn/index_btn_pressed.png'); 
}

.container {
  background-image: url('./assets/Containers/container_ui.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* height: 674px;
  width: 768px; */
  /* height: 606px; */
  width: 691px;
  max-height: 606px;
}

.container-header {
  background-image: url('./assets/Containers/header_1_ui.png');
  background-color: transparent;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* width: 386px; */
  padding: 0 1em;
  height: 90px;
  display: flex;
}

.select-btn {
  background-image: url('./assets/Buttons/Select Btn/slot_btn_ui.png');
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  font-family: Pixolletta;

}

.select-btn:hover {
  background-image: url('./assets/Buttons/Select Btn/slot_btn_select_ui.png');
}
